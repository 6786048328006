import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { User } from './user.model';
import { map, tap } from 'rxjs/operators';

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  localId: string;
  expiresIn: string;
  registered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public email:string;

  private _user = new BehaviorSubject<User>(null);
  

  get userIsAuthenticated() {
    console.log('valor de -user');
    console.log(this._user);
    console.log('retornamos valor de user.token');
    
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          console.log('valor del token');
          console.log(user.token);
          console.log(user.email);
          
          //return !!user.token;
          return true;
        } else {
          console.log('entro donde el usuario no está definido');
          return true;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  


  constructor( private http: HttpClient ) { 

  }

  signup(email:string, password:string)
  {
      return this.http.post<AuthResponseData>(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${environment.firebaseAPIKey}`, 
      {email:email, password:password, returnSecureToken:true})
      .pipe(tap(this.setUserData.bind(this)));
  }

  login(email: string, password: string) {
    console.log('aqui llego');
    return this.http.post<AuthResponseData>(
      `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${
        environment.firebaseAPIKey
      }`,
      { email: email, password: password }
      
    ).pipe(tap(this.setUserData.bind(this)));
   
  }

  logout(){
   
    this._user.next(null);
  }

  private setUserData(userData: AuthResponseData) {
    userData => {
      const expirationTime = new Date (new Date().getTime() + (+userData.expiresIn * 1000));
      this._user.next(
        new User(
          userData.localId,
            userData.email,              
        userData.idToken,
         expirationTime)

       
      )
    }

  }
}
