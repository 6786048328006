export class User {
    constructor(
      public id: string,
      public email: string,
      private _token: string,
      private tokenExpirationDate: Date
    ) {}
  
    get token() {
      if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
        console.log('devolvemos null');
        return null;
      }
      console.log('token dentro');
      console.log(this._token);
      return this._token;
    }
  }
         

